/* SPINNER */
.spinner-border {
	display: inline-block;
	vertical-align: text-bottom;
	border: 3px solid;
	border-right-color: transparent;
	border-radius: 50%;
	-webkit-animation: spinner-border .75s linear infinite;
	animation: spinner-border .75s linear infinite;
}

@keyframes spinner-border {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

/* END SPINNER */

